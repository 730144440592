@font-face {
  font-family: "Soleil";
  src: local("Soleil"),
    url("./fonts/soleil-cufonfonts/SoleilRegular.otf") format("truetype");
  font-weight: normal;
  descent-override: 0%;
  ascent-override: 80%;
}

input::placeholder {
    overflow: visible;
}

@font-face {
  font-family: "Soleil";
  src: local("Soleil"),
    url("./fonts/soleil-cufonfonts/SoleilBold.otf") format("truetype");
  font-weight: bold;
  descent-override: 0%;
  ascent-override: 80%;

}
@font-face {
  font-family: "Soleil";
  src: local("Soleil"),
    url("./fonts/soleil-cufonfonts/SoleilLight.otf") format("truetype");
  font-weight: lighter;
  descent-override: 0%;
  ascent-override: 80%;
}
