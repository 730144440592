.container {
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.card-container {
  width: 350px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin: 10px;
  padding: 30px;
  justify-content: center;
}

.logo {
  width: 80%;
  margin: 10px;
  margin-bottom: 40px;
}
